import React, { useState } from 'react';
import kws from '../images/clients/kws.png'; // Example import of an image
import Modal from 'react-modal';
import PaymentModal from './PaymentModal'; // Import PaymentModal component
// import anotherImage from '../path/to/anotherImage'; // Example import of another image

const clientImage = {
  height: '10rem',
  width: 'auto',
  mixBlendMode: 'colorBurn'
};

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
  },
};

Modal.setAppElement('#root');

const Clients = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);

  const projects = [
    {
      name: 'Rental Management System',
      image: kws,
      previewLink: 'https://algotech.co.ke',
      sourceCodeLink: '/download/mrk.zip',
      amount: 2 // Amount for the project
    },
    // {
    //   name: 'Another Project',
    //   image: anotherImage, // Use imported image
    //   previewLink: 'https://example.com',
    //   sourceCodeLink: '/download/another.zip',
    //   amount: 20 // Amount for this project
    // },
    // Add more projects as needed
  ];

  const openModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProject(null);
    setPhoneNumber('');
    setEmail('');
    setConfirmEmail('');
    setPaymentStatus(null);
  };

  const handlePayNow = (paymentData) => {
    return new Promise((resolve, reject) => {
      fetch('/algotech/api/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return (response.json());
        })
        .then((data) => {
          console.log('Payment response:', data);
          setPaymentStatus(data);
          return resolve(data)
        })
        .catch((error) => {
          console.error('Error initiating payment:', error);
          return reject(error)
        });

    })

  };

  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Software Solutions</h2>
          <div className='flex justify-center'>
            <div className='w-24 border-b-4 border-blue-900'></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Some of our software solutions.</h2>
        </div>

        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-4">
            {projects.map((project, index) => (
              <div key={index} className="text-center">
                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-50 hover:opacity-100">
                  <img src={project.image} alt={project.name} />
                </div>
                <h3 className="mt-2 text-xl font-semibold">{project.name}</h3>
                <div className="mt-2">
                  <a href={project.previewLink} target="_blank" rel="noopener noreferrer" className="text-blue-700 underline">Preview</a>
                </div>
                <div className="mt-2">
                  <button onClick={() => openModal(project)} className="text-blue-700 underline">Download Source Code</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Payment Modal */}
      {modalOpen && (
        <PaymentModal
          isOpen={modalOpen}
          closeModal={closeModal}
          handlePayment={handlePayNow}
          amount={selectedProject ? selectedProject.amount : 0}
        />
      )}
    </div>
  );
};

export default Clients;
