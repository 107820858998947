import React, { useState } from 'react';
import Modal from 'react-modal';
import { ClockLoader } from "react-spinners";

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
  },
};

Modal.setAppElement('#root');

const PaymentModal = ({ isOpen, closeModal, handlePayment, amount }) => {
  const [busy, setBusy] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('M-Pesa'); // Default to M-Pesa
  const [paymentStatus, setPaymentStatus] = useState(null);

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= 9) {
      setPhoneNumber(input);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);

    // Validate email confirmation
    if (email !== confirmEmail) {
      alert('Emails do not match.');
      setBusy(false);
      return;
    }

    // Perform payment logic
    try {
      const response = await handlePayment({
        phoneNumber: `254${phoneNumber}`,
        email,
        paymentMethod,
        amount,
      });

      if (response.message) {
        setPaymentStatus(response.message);
      } else if (response.error) {
        setPaymentStatus(response.error);
      }

      setBusy(false);
    } catch (error) {
      console.error('Payment error:', error);
      setPaymentStatus('Payment failed');
      setBusy(false);
    }
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Payment Modal"
    >
      <h2><u><b>Enter Payment Details</b></u></h2>
      <form onSubmit={handleSubmit}>
        <label>
          Phone Number:
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>254</span>
            <input
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter phone number"
              required
              style={{ marginLeft: '5px' }}
            />
          </div>
        </label>
        <br />
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            required
          />
        </label>
        <br />
        <label>
          Confirm Email:
          <input
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            placeholder="Confirm email address"
            required
          />
        </label>
        <br />
        <p>Amount: Ksh. {amount}</p>
        <label>
          Payment Method:
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            required
          >
            <option value="M-Pesa">M-Pesa</option>
            {/* Add other payment methods as options */}
          </select>
        </label>
        <br />
        {!busy ? (
          <button type="submit" style={{ backgroundColor: 'blue', color: 'white', padding: '10px', cursor: 'pointer' }}>
            Pay Now
          </button>
        ) : (
          <ClockLoader
            loading={busy}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </form>

      {paymentStatus && (
        <div style={{ marginTop: '20px' }}>
          {paymentStatus === 'Payment successful and email sent.' && (
            <p style={{ color: 'green' }}>Payment successful! A download link has been sent to your email.</p>
          )}
          {paymentStatus === 'Payment successful but failed to send email.' && (
            <p style={{ color: 'orange' }}>Payment successful but failed to send email.</p>
          )}
          {paymentStatus === 'Payment failed' && (
            <p style={{ color: 'red' }}>Payment failed. Please try again.</p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default PaymentModal;
